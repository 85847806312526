var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs, editMode }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({staticClass:"document-selector__document-btn font-weight-regular overflow-hidden w-full px-2",staticStyle:{"height":"unset !important"},attrs:{"text":"","block":"","small":"","outlined":"","elevation":"0"}},'v-btn',attrs,false),{
          ...on,
          click: e => {
            e.preventDefault()
            e.stopPropagation()
            _setup.handleOpen()
          },
        }),[_c('div',{staticClass:"d-flex flex-column align-start py-2 w-full",staticStyle:{"max-width":"100%"}},[_c('v-btn',{staticClass:"font-weight-regular",staticStyle:{"max-width":"100%","border":"solid 1px var(--color-foreground-high)"},attrs:{"small":"","block":"","depressed":"","outlined":"","height":"24px !important"}},[_c('v-icon',{staticClass:"mx-1",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_setup.mdiPlus)+" ")]),_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(" Tambahkan Document ")])],1),(_vm.value?.length > 0)?_vm._l((_vm.value.slice(0, 3)),function(item){return _c('v-hover',{key:item.uuid,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-chip',{staticClass:"mt-1 overflow-hidden",staticStyle:{"cursor":"pointer","max-width":"100%"},attrs:{"small":"","label":""},on:{"click":[function($event){return _vm.$router.push({ name: 'document-detail', params: { id: item.uuid } })},function($event){$event.preventDefault();$event.stopPropagation();}]}},[_c('v-icon',{staticClass:"mx-1",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_setup.mdiFileDocumentOutline)+" ")]),_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(" "+_vm._s(item.name.length >= 45 ? item.name.slice(0, 45) + '...' : item.name)+" ")]),_c('v-spacer'),_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-1",attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_setup.mdiOpenInNew)+" ")])],1)],1)]}}],null,true)})}):_vm._e()],2)])]},{"on":{
        ...on,
        click: e => {
          e.preventDefault()
          e.stopPropagation()
          _setup.handleOpen()
        },
      },"attrs":attrs,"editMode":editMode,"value":_setup.localValue})]}}],null,true),model:{value:(_setup.editMode),callback:function ($$v) {_setup.editMode=$$v},expression:"editMode"}},[_c(_setup.DocumentSelector,{attrs:{"value":_setup.localValue},on:{"update:selectedDocuments":function($event){_setup.localValue = $event.map(item => {
        return {
          uuid: item.uuid,
          name: item.name,
        }
      })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }