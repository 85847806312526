var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"max-width":"300","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(!_vm.block),expression:"!block"}],staticClass:"font-weight-regular",class:_vm.block ? 'justify-start px-0 date-input-button' : '',attrs:{"text":"","x-small":"","block":_vm.block,"plain":_vm.block,"elevation":"0"}},'v-btn',attrs,false),{
          ...on,
          click: e => {
            e.preventDefault()
            e.stopPropagation()
            _setup.handleOpen()
          },
        }),[_vm._t("default",function(){return [_c('span',[_vm._v(_vm._s(_vm.value ? _setup.dateFormat(_vm.value, 3) : '-'))])]})],2)]},{"on":{
        ...on,
        click: e => {
          e.preventDefault()
          e.stopPropagation()
          _setup.handleOpen()
        },
      },"attrs":attrs,"editMode":_setup.editMode})]}}],null,true),model:{value:(_setup.editMode),callback:function ($$v) {_setup.editMode=$$v},expression:"editMode"}},[_c(_setup.DateTimeSelect,{on:{"reset":function($event){_setup.localValue = _vm.value}},model:{value:(_setup.localValue),callback:function ($$v) {_setup.localValue=$$v},expression:"localValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }