<template>
  <div class="w-full h-full">
    <!-- Property – String -->
    <v-text-field
      v-if="type === 'string'"
      v-model="localValue"
      class="flex-fill"
      :placeholder="`Masukan ${attributePropertyMap[property]?.message || 'Nilai'}`"
      outlined
      dense
      hide-details="auto"
      :rules="[required]"
      @input="$emit('input', localValue)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="margin-top: 1.5px"
        >
          {{ icon }}
        </v-icon>
      </template>
    </v-text-field>

    <!-- Property – Number -->
    <v-text-field
      v-else-if="type === 'integer'"
      v-model="localValue"
      class="flex-fill"
      :placeholder="`Masukan ${attributePropertyMap[property]?.message || 'Nilai'}`"
      type="number"
      outlined
      dense
      hide-details="auto"
      :rules="[required]"
      @input="$emit('input', localValue)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="margin-top: 1.5px"
        >
          {{ icon }}
        </v-icon>
      </template>
    </v-text-field>

    <!-- Property – Date -->
    <DateInput
      v-else-if="type === 'date'"
      :value="localValue || null"
      @input="
        localValue = $event
        $emit('input', $event)
      "
    >
      <template #activator="{ attrs, on, editMode }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          outlined
          class="d-flex justify-start px-3 mb-1"
          minHeight="40px"
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="editMode = true"
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icon }}
          </v-icon>

          <span
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            {{
              localValue
                ? dateFormat(localValue, 3)
                : `Pilih ${attributePropertyMap[property]?.message || 'Nilai'}`
            }}
          </span>
        </v-btn>
      </template>
    </DateInput>

    <!-- Property – Priority -->
    <template v-else-if="type === 'priority'">
      <SingleSelectInput
        v-if="![11, 12].includes(operator)"
        :value="localValue"
        :items="jobTypeDetail?.priorities"
        @input="
          localValue = $event
          $emit('input', $event?.id)
        "
      >
        <template #activator="{ attrs, on, editMode }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            depressed
            outlined
            class="d-flex justify-start px-3 mb-0"
            minHeight="40px"
            style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
            @click="editMode = true"
          >
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ icon }}
            </v-icon>

            <v-chip
              v-if="localValue"
              class="ml-1 mr-2"
              v-on="on"
              v-bind="attrs"
              x-small
              label
              style="cursor: pointer"
              :color="localValue.color"
              @click="editMode = true"
            >
              {{ localValue.name }}
            </v-chip>
            <span
              v-else
              class="font-weight-regular"
              style="opacity: 0.6"
            >
              Pilih Prioritas
            </span>
          </v-btn>
        </template>
      </SingleSelectInput>

      <MultiSelectInput
        v-else
        :value="localValue"
        :items="jobTypeDetail?.priorities"
        @input="
          localValue = $event
          $emit(
            'input',
            $event.map(data => data.id),
          )
        "
      >
        <template #activator="{ attrs, on, editMode }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            depressed
            outlined
            class="d-flex justify-start px-3 mb-0"
            minHeight="40px"
            style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
            @click="editMode = true"
          >
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ icon }}
            </v-icon>

            <template v-if="localValue">
              <v-chip
                v-for="item in localValue"
                :key="`ATM_CF_Status-${item.id}`"
                class="ml-1 mr-2"
                v-on="on"
                v-bind="attrs"
                x-small
                label
                style="cursor: pointer"
                :color="item.color"
                @click="editMode = true"
              >
                {{ item.name }}
              </v-chip>
            </template>

            <span
              v-else
              class="font-weight-regular"
              style="opacity: 0.6"
            >
              Pilih Prioritas
            </span>
          </v-btn>
        </template>
      </MultiSelectInput>
    </template>

    <!-- Property – Status -->
    <template v-else-if="type === 'status'">
      <SingleSelectInput
        v-if="![11, 12].includes(operator)"
        :value="localValue"
        :items="jobTypeDetail?.statuses"
        @input="
          localValue = $event
          $emit('input', $event?.id)
        "
      >
        <template #activator="{ attrs, on, editMode }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            depressed
            outlined
            class="d-flex justify-start px-3 mb-0"
            minHeight="40px"
            style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
            @click="editMode = true"
          >
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ icon }}
            </v-icon>

            <v-chip
              v-if="localValue"
              class="ml-1 mr-2"
              v-on="on"
              v-bind="attrs"
              x-small
              label
              style="cursor: pointer"
              :color="localValue.color"
              @click="editMode = true"
            >
              {{ localValue.name }}
            </v-chip>
            <span
              v-else
              class="font-weight-regular"
              style="opacity: 0.6"
            >
              Pilih Prioritas
            </span>
          </v-btn>
        </template>
      </SingleSelectInput>

      <MultiSelectInput
        v-else
        :value="localValue"
        :items="jobTypeDetail?.statuses"
        @input="
          localValue = $event
          $emit(
            'input',
            $event.map(data => data.id),
          )
        "
      >
        <template #activator="{ attrs, on, editMode }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            depressed
            outlined
            class="d-flex justify-start px-3 mb-0"
            minHeight="40px"
            style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
            @click="editMode = true"
          >
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ icon }}
            </v-icon>

            <template v-if="localValue">
              <v-chip
                v-for="item in localValue"
                :key="`ATM_CF_Status-${item.id}`"
                class="ml-1 mr-2"
                v-on="on"
                v-bind="attrs"
                x-small
                label
                style="cursor: pointer"
                :color="item.color"
                @click="editMode = true"
              >
                {{ item.name }}
              </v-chip>
            </template>

            <span
              v-else
              class="font-weight-regular"
              style="opacity: 0.6"
            >
              Pilih Prioritas
            </span>
          </v-btn>
        </template>
      </MultiSelectInput>
    </template>

    <!-- Property – Assign User -->
    <v-menu
      v-else-if="type === 'user'"
      @input="openInteraction = !openInteraction"
      :close-on-content-click="false"
      style="z-index: 206"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          class="d-flex justify-start px-3 mb-2"
          minHeight="40px"
          style="
            border-radius: 5px;
            width: 100%;
            height: 40px !important;
            border: solid 1px var(--color-foreground-high);
          "
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>

          <UserLimitElements
            v-if="localValue && localValue?.length > 0"
            :users="
              usersList.reduce((acc, user) => {
                if (localValue.includes(user.id)) acc.push({ user })

                return acc
              }, [])
            "
            :size="26"
          />

          <span
            v-else
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            Pilih Assignee
          </span>
        </v-btn>
      </template>

      <UserTeamSelector
        v-if="openInteraction"
        v-model="localValue"
        width="100%"
        object-type="job-type"
        :object-id="jobTypeDetail.id"
        @input="$emit('input', localValue)"
      />
    </v-menu>

    <!-- Property – Document -->
    <v-menu
      v-else-if="type === 'document'"
      @input="openInteraction = !openInteraction"
      :close-on-content-click="false"
      style="z-index: 206"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          depressed
          class="document-selector__document-btn d-flex justify-start px-3 mb-2"
          minHeight="40px"
          style="
            border-radius: 5px;
            width: 100%;
            height: 40px !important;
            border: solid 1px var(--color-foreground-high);
          "
        >
          <v-icon
            class="ml-1 mr-2"
            size="20"
            style="opacity: 0.8"
          >
            {{ icon }}
          </v-icon>

          <template v-if="localValue?.length > 0">
            <v-chip
              v-for="item in localValue"
              :key="item.uuid"
              class="mx-1 overflow-hidden"
              small
              label
              style="cursor: pointer"
            >
              <span
                class="text-truncate"
                style="max-width: 100%"
              >
                {{ item.name.length >= 45 ? item.name.slice(0, 45) + '...' : item.name }}
              </span>
            </v-chip>
          </template>

          <span
            v-else
            class="font-weight-regular"
            style="opacity: 0.6"
          >
            Pilih Document
          </span>
        </v-btn>
      </template>

      <DocumentSelector
        :value="localValue"
        @update:selectedDocuments="localValue = $event"
      />
    </v-menu>

    <!-- Property – Custom Attribute, Multiple -->
    <v-autocomplete
      v-else-if="type === 'multiple' || type === 'single'"
      v-model="localValue"
      flat
      dense
      outlined
      auto-select-first
      hide-details="auto"
      placeholder="Masukan Pilihan"
      item-text="name"
      item-value="id"
      :multiple="type === 'multiple'"
      :items="localList"
      @input="handleCustomAttributeSelectEmpty"
    >
      <template #prepend-inner>
        <v-icon
          class="ml-1 mr-2"
          size="20"
          style="margin-top: 1.5px"
        >
          {{ icon }}
        </v-icon>
      </template>

      <template #selection="{ item }">
        <v-chip
          small
          label
        >
          {{ item.name }}
        </v-chip>
      </template>

      <template #item="{ item }">
        <div class="d-flex align-center flex-grow-1 py-3">
          <span class="subtitle-2"> {{ item.name }}</span>
        </div>
      </template>
    </v-autocomplete>

    <!-- Type – User Single Select -->
    <v-autocomplete
      v-else-if="type === 'user-single-select'"
      v-model="localValue"
      flat
      dense
      outlined
      auto-select-first
      hide-details="auto"
      placeholder="Pilih User"
      item-text="name"
      item-value="id"
      :rules="[required]"
      :items="localList"
      @input="$emit('input', $event)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="opacity: 0.8; margin-top: 1.5px"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </template>

      <template #selection="{ item }">
        <v-avatar
          size="24"
          class="mr-2 v-avatar-light-bg"
        >
          <v-img
            v-if="item.photo"
            :src="item.photo"
          />
          <span
            class="text-caption"
            v-else
            >{{ avatarText(item.name) }}</span
          >
        </v-avatar>

        <span>{{ item.name }}</span>

        <v-chip
          x-small
          v-if="item.id === currentUserId"
          color="primary"
          class="ml-2 px-2"
        >
          Anda
        </v-chip>
      </template>

      <template #item="{ item }">
        <div class="d-flex align-center flex-grow-1 py-3">
          <v-avatar
            size="24"
            class="ml-1 mr-2 v-avatar-light-bg"
          >
            <v-img
              v-if="item.photo"
              :src="item.photo"
            />
            <span
              class="text-caption"
              v-else
              >{{ avatarText(item.name) }}</span
            >
          </v-avatar>

          <span>{{ item.name }}</span>

          <v-chip
            x-small
            v-if="item.id === currentUserId"
            color="primary"
            class="ml-2 px-2"
          >
            Anda
          </v-chip>
        </div>
      </template>
    </v-autocomplete>

    <!-- Type – Document Tags -->
    <v-autocomplete
      v-else-if="type === 'document-tag'"
      v-model="localValue"
      flat
      dense
      outlined
      auto-select-first
      mur
      hide-details="auto"
      placeholder="Pilih Document Tag"
      item-text="name"
      item-value="id"
      :rules="[required]"
      :items="documentTags"
      @input="$emit('input', $event)"
    >
      <template #prepend-inner>
        <v-icon
          class="mx-1"
          size="20"
          style="opacity: 0.8; margin-top: 1.5px"
        >
          {{ icons.mdiTagOutline }}
        </v-icon>
      </template>

      <template #selection="{ item }">
        <v-chip
          small
          label
          :color="item.color"
          class="mr-2"
        >
          {{ item.name }}
        </v-chip>
      </template>

      <template #item="{ item }">
        <v-chip
          small
          label
          :color="item.color"
          class="mr-2"
        >
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>

    <div
      v-else
      class="d-flex align-center px-3"
      style="
        border-radius: 5px;
        min-height: 40px;
        width: 100%;
        border: solid 1px var(--color-foreground-high);
      "
    >
      <v-icon
        class="ml-1 mr-2"
        size="20"
        style="opacity: 0.8"
      >
        {{ icons.mdiAlertOutline }}
      </v-icon>

      <span
        class="font-weight-regular text--disabled"
        style="color: var(--ck-color-base-text)"
      >
        Tipe Data belum di implementasikan!
      </span>
    </div>
  </div>
</template>

<script>
import { required } from '@/@core/utils/validation'
import DocumentSelector from '@/components/inputs/DocumentSelector.vue'
import DateInput from '@/components/inputs/editable/DateInput.vue'
import MultiSelectInput from '@/components/inputs/editable/MultiSelectInput.vue'
import SingleSelectInput from '@/components/inputs/editable/SingleSelectInput.vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import UserLimitElements from '@/components/misc/UserLimitElements.vue'
import useDocument from '@/composables/useDocument'
import useTeam from '@/composables/useTeam'
import { attributePropertyMap } from '@/constants/automation'
import store from '@/store'
import dateFormat from '@/utils/dateFormat'
import { avatarText } from '@core/utils/filter'
import { mdiAccountOutline, mdiAlertOutline, mdiFileDocumentOutline, mdiTagOutline } from '@mdi/js'
import { computed, inject, onMounted, ref, watch } from 'vue'

export default {
  components: {
    SingleSelectInput,
    MultiSelectInput,
    DateInput,
    UserTeamSelector,
    DocumentSelector,
    UserLimitElements,
  },

  props: {
    value: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    property: {
      type: String,
    },
    operator: {
      type: Number,
    },
    jobTypeId: {
      type: Number,
    },
    isEditing: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const folderDetail = inject('folderDetail')
    const jobTypeDetail = inject('jobTypeDetail')
    const usersList = inject('usersList')
    const localValue = ref(props.value)
    const localList = ref()
    const openInteraction = ref(false)
    const currentUserId = computed(() => store.getters.getUserData.id)

    const { fetchTeamHiearchy } = useTeam()
    const { documentTags, fetchDocumentTags } = useDocument()

    const findCorrespondingCustomAttribute = () =>
      (localList.value = [
        {
          id: null,
          name: 'KOSONG',
        },
        {
          header: 'Isi dari Custom Attribut',
        },
        ...jobTypeDetail.value.custom_attributes.find(
          item => item.id == props.property.split('CA_')[1],
        )?.options,
      ])

    const findUsersList = async () => {
      const result = await fetchTeamHiearchy({ jobTypeId: props.jobTypeId })

      localList.value = result.userList
    }

    const findCorrespondingItems = () => {
      if (props.property?.startsWith('CA_')) {
        if (props.type === 'single' && props.isEditing && Array.isArray(props.value)) {
          localValue.value = props.value[0]
          emit('input', props.value[0])
        }

        return findCorrespondingCustomAttribute()
      }

      // Priorities
      if (props.property == 2) {
        if (props.isEditing && [11, 12].includes(props.operator)) {
          return (localValue.value = props.value.map(itemId =>
            jobTypeDetail.value?.properties.find(status => status.id == itemId),
          ))
        }

        if (props.isEditing && Array.isArray(props.value)) {
          localValue.value = props.value[0]
          emit('input', props.value[0])
        }

        localValue.value = jobTypeDetail.value?.priorities.find(
          priority => priority.id == localValue.value,
        )
      }

      // Status
      if (props.property == 3) {
        if (props.isEditing && [11, 12].includes(props.operator)) {
          return (localValue.value = props.value.map(itemId =>
            jobTypeDetail.value?.statuses.find(status => status.id == itemId),
          ))
        }

        if (props.isEditing && Array.isArray(props.value)) {
          localValue.value = props.value[0]
          emit('input', props.value[0])
        }

        localValue.value = jobTypeDetail.value?.statuses.find(
          status => status.id == localValue.value,
        )
      }

      // User Selector, Single Select
      if (props.type == 'user-single-select') findUsersList()

      if (props.type == 'document-tag') fetchDocumentTags()
    }

    const handleUserAssigneeSelector = () => {
      if (!localValue.value.assign) return

      const assignUserIds = localValue.value.assign.assign_user_id.slice(1)

      if (
        assignUserIds.length !== localValue.value.length ||
        assignUserIds.some((id, index) => id !== localValue.value[index])
      ) {
        localValue.value.assign.assign_user_id = [
          localValue.value.assign.assign_user_id[0],
          ...localValue.value,
        ]
      }
    }

    const handleCustomAttributeSelectEmpty = value => {
      if (value === null || (Array.isArray(value) && value.includes(null))) {
        localValue.value = null
        emit('input', null)
      } else {
        emit('input', localValue.value)
      }
    }

    onMounted(findCorrespondingItems)

    watch([folderDetail, jobTypeDetail, () => props.jobType], () => {
      console.log('ACF fd jtd prop.jtd triggred')
      console.log('is props', !props.value)
      console.log('what props', props.value)

      if (!props.value) {
        localValue.value = undefined
        localList.value = undefined
        emit('input', undefined)
      } else {
        if (props.type == 'status' || props.type == 'priority') {
          localValue.value = props.value
          emit('input', props.value?.id)
          findCorrespondingItems()
        }
      }
    })

    watch([() => props.operator, () => props.property], () => {
      localValue.value = undefined
      localList.value = undefined
      emit('input', undefined)

      if (props.property?.startsWith('CA_')) return findCorrespondingCustomAttribute()
    })

    watch(() => props.isEditing, findCorrespondingItems)

    return {
      folderDetail,
      jobTypeDetail,
      usersList,
      localValue,
      localList,
      currentUserId,

      openInteraction,
      handleUserAssigneeSelector,
      handleCustomAttributeSelectEmpty,

      documentTags,
      attributePropertyMap,

      avatarText,
      dateFormat,
      required,

      icons: {
        mdiAccountOutline,
        mdiAlertOutline,
        mdiFileDocumentOutline,
        mdiTagOutline,
      },
    }
  },
}
</script>
