import { useSubscription } from '@vue/apollo-composable'

const useSubscriptionV1 = (query, param) => {
  const subscription = useSubscription(query, param)

  subscription.onError(() => {
    subscription.restart()
  })

  return {
    result: subscription.result,
  }
}

export default useSubscriptionV1
