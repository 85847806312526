<template>
  <v-menu
    v-model="editMode"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs, editMode }">
      <slot
        name="activator"
        :on="{
          ...on,
          click: e => {
            e.preventDefault()
            e.stopPropagation()
            handleOpen()
          },
        }"
        :attrs="attrs"
        :editMode="editMode"
        :value="localValue"
      >
        <v-btn
          text
          v-on="{
            ...on,
            click: e => {
              e.preventDefault()
              e.stopPropagation()
              handleOpen()
            },
          }"
          v-bind="attrs"
          block
          small
          outlined
          elevation="0"
          class="document-selector__document-btn font-weight-regular overflow-hidden w-full px-2"
          style="height: unset !important"
        >
          <div
            class="d-flex flex-column align-start py-2 w-full"
            style="max-width: 100%"
          >
            <v-btn
              class="font-weight-regular"
              small
              block
              depressed
              outlined
              height="24px !important"
              style="max-width: 100%; border: solid 1px var(--color-foreground-high)"
            >
              <v-icon
                class="mx-1"
                size="16"
              >
                {{ mdiPlus }}
              </v-icon>

              <span
                class="text-truncate"
                style="max-width: 100%"
              >
                Tambahkan Document
              </span>
            </v-btn>

            <template v-if="value?.length > 0">
              <v-hover
                v-for="item in value.slice(0, 3)"
                :key="item.uuid"
                v-slot="{ hover }"
              >
                <v-chip
                  class="mt-1 overflow-hidden"
                  small
                  label
                  style="cursor: pointer; max-width: 100%"
                  @click="$router.push({ name: 'document-detail', params: { id: item.uuid } })"
                  @click.prevent.stop
                >
                  <v-icon
                    class="mx-1"
                    size="16"
                  >
                    {{ mdiFileDocumentOutline }}
                  </v-icon>

                  <span
                    class="text-truncate"
                    style="max-width: 100%"
                  >
                    {{ item.name.length >= 45 ? item.name.slice(0, 45) + '...' : item.name }}
                  </span>

                  <v-spacer />

                  <v-slide-x-reverse-transition mode="out-in">
                    <v-icon
                      v-show="hover"
                      class="ml-1"
                      size="14"
                    >
                      {{ mdiOpenInNew }}
                    </v-icon>
                  </v-slide-x-reverse-transition>
                </v-chip>
              </v-hover>
            </template>
          </div>
        </v-btn>
      </slot>
    </template>

    <DocumentSelector
      :value="localValue"
      @update:selectedDocuments="
        localValue = $event.map(item => {
          return {
            uuid: item.uuid,
            name: item.name,
          }
        })
      "
    />
  </v-menu>
</template>

<script setup>
import { mdiFileDocumentOutline, mdiPlus, mdiOpenInNew } from '@mdi/js'
import _ from 'lodash'
import { ref, watch } from 'vue'
import DocumentSelector from '../DocumentSelector.vue'
import errorHandling from '@/utils/errorHandling'

const emit = defineEmits(['input'])
const props = defineProps({
  /**
   * Example data :
   * [ 'document-uuid', // typeof String ] // typeof Array
   */
  value: {
    type: Array,
    default: null,
  },

  /* ? This validator props are used to determine whether the dialog is alowed to be open or not
   *   if this props is not present, validator is ignored and dialog would always open
   *
   *   @example => :validator="job_type.folder_permission[0].permission_type === 'Admin'"
   */
  validator: {
    type: Boolean,
    default: undefined,
  },
})

let storePrevious = props.value
const localValue = ref(props.value)
const editMode = ref(false)

const handleOpen = () => {
  if (props.validator === undefined || props.validator) editMode.value = true
  else if (props.validator === false)
    errorHandling('Anda tidak memiliki Permission untuk melakukan aksi ini!')
}

watch(editMode, val => {
  if (props.validator === false) return (editMode.value = false)

  if (!val && !_(storePrevious).xorWith(localValue.value, _.isEqual).isEmpty()) {
    emit(
      'input',
      localValue.value.map(item => item.uuid),
    )

    storePrevious = localValue.value
  }
})
</script>

<style lang="scss">
.document-selector__document-btn {
  .v-btn__content {
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
