<template>
  <v-row
    no-gutters
    dense
    class="w-full h-full"
    v-if="!isLoading"
  >
    <!-- Default  – Folder & JobType Detail -->
    <template v-if="actionType !== 7">
      <v-col
        class="px-1 pb-2"
        cols="12"
        :md="[4, 5, 6].includes(actionType) ? 12 : 6"
      >
        <v-autocomplete
          v-model="localFolder"
          class="text-truncate"
          solo
          flat
          dense
          outlined
          return-object
          auto-select-first
          item-value="id"
          item-text="name"
          hide-details="auto"
          placeholder="Pilih Folder"
          :clearable="[4, 5].includes(actionType)"
          :items="folderList"
        >
          <template #selection="{ item }">
            <div class="d-flex align-center py-3 px-1 overflow-hidden">
              <v-icon
                :color="item.color"
                class="mx-1"
                size="18"
              >
                {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
              </v-icon>

              <span class="text-truncate">{{ item.name }}</span>
            </div>
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center py-3 px-1 w-full">
              <v-icon
                :color="item.color"
                class="mx-1"
                size="18"
              >
                {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
              </v-icon>

              <span class="text-truncate">{{ item.name }}</span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="![4, 5, 6].includes(actionType)"
        class="px-1 pb-2"
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="localJobType"
          class="text-truncate"
          solo
          flat
          dense
          outlined
          return-object
          auto-select-first
          height="100%"
          item-value="id"
          item-text="name"
          hide-details="auto"
          placeholder="Pilih Job Type"
          :rules="[required]"
          :items="localJobTypeList"
        >
          <template #selection="{ item }">
            <div class="d-flex align-center py-3 px-1 overflow-hidden">
              <v-badge
                class="mr-1"
                dot
                inline
                :color="item.color"
              />

              <v-icon
                v-if="!item.is_public"
                size="14"
                class="mr-1"
              >
                {{ icons.mdiLockOutline }}
              </v-icon>

              <span class="text-truncate">{{ item.name }}</span>
            </div>
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center py-3 px-1 w-full">
              <v-badge
                class="mr-1"
                dot
                inline
                :color="item.color"
              />

              <v-icon
                v-if="!item.is_public"
                size="14"
                class="mr-1"
              >
                {{ icons.mdiLockOutline }}
              </v-icon>

              <span class="text-truncate">{{ item.name }}</span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        class="px-1 mt-1 mb-3"
        cols="12"
      >
        <v-divider></v-divider>
      </v-col>
    </template>

    <!-- Action Type – Job -->
    <!-- Action Type 1 – Create Job -->
    <!-- Action Type 2 – Update Job -->
    <template v-if="[1, 2, 3].includes(actionType)">
      <template v-if="selectedField.includes('name')">
        <template v-if="actionType === 1">
          <v-col
            class="pb-2 px-1"
            cols="12"
          >
            <v-card
              class="py-2 px-3"
              outlined
            >
              <span
                class="text-subtitle-2 font-weight-medium d-flex"
                style="opacity: 0.75"
              >
                <v-icon
                  class="mr-2"
                  size="18"
                  style="margin-top: 2px"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
                Menciptakan Job memerlukan Status dan Priority secara bawaan, apabila data tersebut
                tidak ada, maka Job tidak akan berhasil terciptakan.
              </span>
            </v-card>
          </v-col>

          <v-col
            v-if="localValue.nameByTrigger"
            class="pb-2 px-1"
            cols="12"
          >
            <span
              class="text-subtitle-2 d-flex"
              style="opacity: 0.75"
            >
              <v-icon
                class="mr-1"
                size="16"
                style="margin-top: 2.5px"
              >
                {{ icons.mdiInformationOutline }}
              </v-icon>
              Nama Job yang akan dibuat akan mengikuti nama dari Job / Dokumen yang memicu Trigger
              Automation ini
            </span>
          </v-col>

          <v-col
            class="px-1 pb-2"
            cols="12"
            :md="localValue.nameByTrigger ? 12 : 3"
          >
            <v-chip
              class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 6px; height: 40px"
              :color="localValue.nameByTrigger ? 'primary' : ''"
              @click="localValue.nameByTrigger = !localValue.nameByTrigger"
            >
              <v-icon
                v-if="localValue.nameByTrigger"
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiPlayCircleOutline }}
              </v-icon>
              <v-icon
                v-else
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiLabelOutline }}
              </v-icon>

              <span class="ml-2 text-subtitle-2 text-truncate">
                {{ localValue.nameByTrigger ? 'Nama Trigger Bawaan' : 'Nama Job Kustom' }}
              </span>
            </v-chip>
          </v-col>
        </template>

        <v-col
          v-show="!localValue.nameByTrigger"
          class="d-flex pb-2 px-1"
          cols="12"
          :md="actionType !== 1 ? 12 : 9"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              v-if="actionType !== 1"
              text
              depressed
              class="mr-2 px-0"
              :color="hover ? 'error' : undefined"
              style="
                border-radius: 5px;
                min-width: 40px !important;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              @click="
                selectedField.splice(selectedField.indexOf('name'), 1)
                localValue.data.name = undefined
              "
            >
              <v-icon size="22">
                {{ icons.mdiDelete }}
              </v-icon>
            </v-btn>
          </v-hover>

          <AutomationCustomField
            class=""
            :isEditing="isEditing"
            :value="localValue.data.name"
            property="1"
            :type="attributePropertyMap[1].type"
            :icon="attributePropertyMap[1].icon"
            @input="localValue.data.name = $event"
          />
        </v-col>
      </template>

      <v-col
        v-if="selectedField.includes('priority')"
        class="d-flex pb-2 px-1"
        cols="12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 1"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('priority'), 1)
              localValue.data.job_priority_id = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.data.job_priority_id"
          property="2"
          :type="attributePropertyMap[2].type"
          :icon="attributePropertyMap[2].icon"
          @input="localValue.data.job_priority_id = $event"
        />
      </v-col>

      <v-col
        v-if="selectedField.includes('status')"
        class="d-flex pb-2 px-1"
        cols="12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 1"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('status'), 1)
              localValue.data.job_status_id = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.data.job_status_id"
          property="3"
          :type="attributePropertyMap[3].type"
          :icon="attributePropertyMap[3].icon"
          @input="localValue.data.job_status_id = $event"
        />
      </v-col>

      <v-col
        v-if="selectedField.includes('start_date')"
        class="d-flex pb-2 px-1"
        cols="12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('start_date'), 1)
              localValue.data.start_date = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.data.start_date"
          property="4"
          :type="attributePropertyMap[4].type"
          :icon="attributePropertyMap[4].icon"
          @input="localValue.data.start_date = $event"
        />

        <v-autocomplete
          v-model="localValue.data.start_date"
          flat
          dense
          outlined
          auto-select-first
          hide-details="auto"
          placeholder="Pilih Tanggal Mulai"
          item-text="label"
          item-value="id"
          :rules="[required]"
          :items="actionAssignList"
        >
          <template #selection="{ item }">
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ item.icon || attributePropertyMap[4].icon }}
            </v-icon>

            {{ item.label }}
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center flex-grow-1 py-3">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              <span class="subtitle-2"> {{ item.label }} </span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
        v-if="selectedField.includes('expected_close_date')"
        class="d-flex pb-2 px-1"
        cols="12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('expected_close_date'), 1)
              localValue.data.expected_close_date = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <v-autocomplete
          v-model="localValue.data.expected_close_date"
          flat
          dense
          outlined
          auto-select-first
          hide-details="auto"
          placeholder="Pilih Tanggal Mulai"
          item-text="label"
          item-value="id"
          :rules="[required]"
          :items="actionAssignList"
        >
          <template #selection="{ item }">
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ item.icon || attributePropertyMap[5].icon }}
            </v-icon>

            {{ item.label }}
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center flex-grow-1 py-3">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              <span class="subtitle-2"> {{ item.label }} </span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <template v-if="selectedField.includes('assign_user_id')">
        <v-col
          class="d-flex pb-2 px-1"
          cols="12"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              text
              depressed
              class="mr-2 px-0"
              :color="hover ? 'error' : undefined"
              style="
                border-radius: 5px;
                min-width: 40px !important;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              @click="
                selectedField.splice(selectedField.indexOf('assign_user_id'), 1)
                localValue.assign.assign_user_id = []
              "
            >
              <v-icon size="22">
                {{ icons.mdiDelete }}
              </v-icon>
            </v-btn>
          </v-hover>

          <v-autocomplete
            v-model="localValueAssigneeAction_Type"
            flat
            dense
            outlined
            auto-select-first
            hide-details="auto"
            placeholder="Pilih Aksi Assign"
            item-text="label"
            item-value="id"
            :rules="[required]"
            :items="actionAssignList"
          >
            <template #selection="{ item }">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              {{ item.label }}
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center flex-grow-1 py-3">
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ item.icon }}
                </v-icon>

                <span class="subtitle-2"> {{ item.label }} </span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="px-1"
          cols="12"
          md="8"
        >
          <v-menu
            @input="handleUserAssigneeSelector()"
            :close-on-content-click="false"
            style="z-index: 206"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                depressed
                class="d-flex justify-start px-3 mb-2"
                minHeight="40px"
                style="
                  border-radius: 5px;
                  width: 100%;
                  height: 40px !important;
                  border: solid 1px var(--color-foreground-high);
                "
              >
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ icons.mdiClipboardAccountOutline }}
                </v-icon>

                <UserLimitElements
                  v-if="
                    localValue.assign?.assign_user_id &&
                    localValue.assign?.assign_user_id.length > 1
                  "
                  :users="
                    usersList.reduce((acc, user) => {
                      if (localValueAssignee.includes(user.id)) acc.push({ user })

                      return acc
                    }, [])
                  "
                  :size="26"
                />

                <span
                  v-else
                  class="font-weight-regular"
                  style="opacity: 0.6"
                >
                  Tidak Memiliki Assignee
                </span>
              </v-btn>
            </template>

            <UserTeamSelector
              v-if="openAssignee"
              v-model="localValueAssignee"
              width="100%"
              object-type="job-type"
              :object-id="localJobType.id"
            />
          </v-menu>
        </v-col>
      </template>

      <v-col
        class="px-1"
        cols="12"
      >
        <v-menu
          bottom
          center
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="v-card v-sheet--outlined"
              depressed
              outlined
              style="
                border-radius: 5px;
                width: 100%;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              v-bind="attrs"
              v-on="on"
            >
              <h4
                class="font-medium pl-2"
                style="color: inherit !important"
              >
                Choose Job Attributes
              </h4>
            </v-btn>
          </template>

          <v-card
            outlined
            class="d-flex flex-column pa-2"
          >
            <v-btn
              v-for="(option, index) in actionJobFieldList"
              :key="`automation_option_action_list${index}`"
              class="mb-2 px-0"
              depressed
              :disabled="['name', 'priority', 'status'].includes(option.name) && actionType === 1"
              :text="selectedField.includes(option.name) ? false : true"
              :color="selectedField.includes(option.name) ? 'primary' : ''"
              style="border-radius: 5px; min-width: 40px !important; height: 40px !important"
              @click="
                selectedField.includes(option.name)
                  ? (selectedField = selectedField.filter(item => item !== option.name))
                  : selectedField.push(option.name)
              "
            >
              <v-icon
                class="mx-2"
                size="20"
              >
                {{ option.icon }}
              </v-icon>

              <h4
                class="font-medium mr-auto"
                style="color: inherit !important"
              >
                {{ option.label }}
              </h4>
            </v-btn>
          </v-card>
        </v-menu>
      </v-col>

      <v-col
        class="my-3"
        cols="12"
      >
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <template v-if="localValue?.custom_attribute && localValue?.custom_attribute?.length > 0">
          <div
            v-for="(attribute, index) in localValue?.custom_attribute"
            :key="`action-custom_attribute_${index}`"
            class="d-flex"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    depressed
                    class="mr-2 px-0"
                    :color="hover ? 'error' : undefined"
                    style="
                      border-radius: 5px;
                      min-width: 40px !important;
                      height: 40px !important;
                      border: solid 1px var(--color-foreground-high);
                    "
                    @click="localValue.custom_attribute.splice(index, 1)"
                  >
                    <v-icon size="22">
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>

              <span>Hapus Custom Attribute</span>
            </v-tooltip>

            <v-row
              class="d-flex mb-2"
              no-gutters
              dense
            >
              <v-col
                class="pr-md-1"
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="attribute.id"
                  flat
                  dense
                  outlined
                  auto-select-first
                  hide-details="auto"
                  placeholder="Pilih Custom Attribute"
                  item-text="name"
                  item-value="id"
                  :rules="[required]"
                  :items="
                    localJobType.custom_attributes.filter(item => {
                      const selectedIds = localValue.custom_attribute
                        .map(attr => attr.id)
                        .filter(id => id !== attribute.id)

                      return !selectedIds.includes(item.id)
                    })
                  "
                >
                  <template #selection>
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                      style="opacity: 0.8"
                    >
                      {{ handleCustomAttributeSelector(attribute.id)?.icon }}
                    </v-icon>

                    {{ localJobType.custom_attributes.find(({ id }) => id === attribute.id)?.name }}
                  </template>

                  <template #item="{ item }">
                    <div class="d-flex align-center flex-grow-1 py-3">
                      <v-icon
                        class="ml-1 mr-2"
                        size="20"
                        style="opacity: 0.8"
                      >
                        {{ customAttributeTypeMap[item.data_type.name]?.icon }}
                      </v-icon>

                      <span class="subtitle-2"> {{ item.name }} </span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                class="pl-md-1"
                cols="12"
                md="8"
              >
                <AutomationCustomField
                  :isEditing="isEditing"
                  :value="attribute.value"
                  :property="`CA_${attribute.id}`"
                  :type="handleCustomAttributeSelector(attribute.id)?.type"
                  :icon="handleCustomAttributeSelector(attribute.id)?.icon"
                  @input="localValue.custom_attribute[index].value = $event"
                />
              </v-col>
            </v-row>
          </div>
        </template>

        <div
          v-else
          class="d-flex align-center justify-center"
          style="min-height: 75px"
        >
          <span class="w-full text-subtitle-2 font-weight-medium text-center">
            {{
              localJobType.custom_attributes?.length > 0
                ? 'Tidak ada Custom Attribute yang akan digunakan'
                : 'Tidak ada Custom Attribute yang tersedia'
            }}
          </span>
        </div>

        <v-btn
          v-if="localJobType.custom_attributes?.length > 0"
          class="mt-2 v-card v-sheet--outlined"
          depressed
          outlined
          :disabled="localValue.custom_attribute?.length === localJobType.custom_attributes?.length"
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="handleAddCustomAttribute()"
        >
          <h4 class="font-medium pl-2">Add Custom Attribute</h4>
        </v-btn>
      </v-col>
    </template>

    <!-- Action Type – Document -->
    <!-- Action Type 5 – Create Document -->
    <!-- Action Type 6 – Update Document -->
    <template v-else-if="[4, 5, 6].includes(actionType)">
      <v-col
        v-if="localValue.documentByTrigger"
        class="pb-2 px-1"
        cols="12"
      >
        <span
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Document yang akan di-update akan mengikuti dari Dokumen yang ter-relasikan oleh Job yang
          memicu Trigger Automation ini
        </span>
      </v-col>
      <v-col
        v-if="actionType == 6"
        class="px-1 pb-2"
        cols="12"
        :md="localValue.documentByTrigger ? 12 : 3"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.documentByTrigger ? 'primary' : ''"
          @click="
            localValue.documentByTrigger = !localValue.documentByTrigger
            localValue.documentByTrigger
              ? (localValue.document_uuids = undefined)
              : (localValue.document_uuids = [])
          "
        >
          <v-icon
            v-if="localValue.documentByTrigger"
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiPlayCircleOutline }}
          </v-icon>
          <v-icon
            v-else
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiLabelOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.documentByTrigger
                ? 'Document Trigger Bawaan'
                : 'Pilih Dokumen Document Kustom'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="!localValue.documentByTrigger"
        class="px-1 pb-2"
        cols="12"
        :md="actionType !== 6 ? 12 : 9"
      >
        <DocumentSelectInput
          :value="localValueSelectedDocument"
          @input="localValue.document_uuids = $event"
        >
          <template #activator="{ on, attrs, value }">
            <v-btn
              text
              v-on="on"
              v-bind="attrs"
              block
              outlined
              elevation="0"
              class="d-flex justify-start overflow-hidden w-full px-2"
              style="
                border-radius: 5px;
                min-width: 40px !important;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
            >
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ icons.mdiFileDocumentOutline }}
              </v-icon>

              <template v-if="value?.length > 0">
                <v-chip
                  v-for="item in value.slice(0, 5)"
                  :key="item.uuid"
                  class="overflow-hidden"
                  small
                  label
                  style="cursor: pointer; max-width: 100%"
                  @click.prevent.stop
                >
                  <span
                    class="text-truncate"
                    style="max-width: 100%"
                  >
                    {{ item.name.length >= 45 ? item.name.slice(0, 45) + '...' : item.name }}
                  </span>

                  <v-spacer />

                  <v-slide-x-reverse-transition mode="out-in">
                    <v-icon
                      v-show="hover"
                      class="ml-1"
                      size="14"
                    >
                      {{ mdiOpenInNew }}
                    </v-icon>
                  </v-slide-x-reverse-transition>
                </v-chip>
              </template>

              <span
                v-else
                class="font-weight-regular"
                style="opacity: 0.6"
              >
                Tidak Ada Document yang Terpilih
              </span>
            </v-btn>
          </template>
        </DocumentSelectInput>
      </v-col>

      <v-col
        v-if="localValue.nameByTrigger"
        class="pb-2 px-1"
        cols="12"
      >
        <span
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Nama Document yang akan dibuat akan mengikuti nama dari Job / Dokumen yang memicu Trigger
          Automation ini
        </span>
      </v-col>
      <v-col
        v-if="actionType !== 6"
        class="px-1 pb-2"
        cols="12"
        :md="localValue.nameByTrigger ? 12 : 3"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.nameByTrigger ? 'primary' : ''"
          @click="localValue.nameByTrigger = !localValue.nameByTrigger"
        >
          <v-icon
            v-if="localValue.nameByTrigger"
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiPlayCircleOutline }}
          </v-icon>
          <v-icon
            v-else
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiLabelOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{ localValue.nameByTrigger ? 'Nama Trigger Bawaan' : 'Nama Document Kustom' }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="selectedField.includes('name') && !localValue.nameByTrigger"
        class="d-flex pb-2 px-1"
        cols="12"
        :md="actionType !== 6 ? 9 : 12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 4"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('name'), 1)
              localValue.name = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <v-text-field
          v-show="!localValue.nameByTrigger"
          v-model="localValue.data.name"
          class="flex-fill"
          placeholder="Masukan Nama Document"
          outlined
          dense
          hide-details="auto"
          :rules="[required]"
          :disabled="localValue.nameByTrigger"
        >
          <template #prepend-inner>
            <v-icon
              class="mx-1"
              size="20"
              style="margin-top: 1.5px"
            >
              {{ icons.mdiFileDocumentOutline }}
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col
        v-if="selectedField.includes('description')"
        class="d-flex pb-2 px-1"
        cols="12"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 4"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('description'), 1)
              localValue.description = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <v-textarea
          v-model="localValue.data.description"
          class="flex-fill"
          placeholder="Masukan Deskripsi Document"
          outlined
          dense
          hide-details="auto"
          :rules="[required]"
        >
          <template #prepend-inner>
            <v-icon
              class="mx-1"
              size="20"
              style="margin-top: 1.5px"
            >
              {{ icons.mdiTextLong }}
            </v-icon>
          </template>
        </v-textarea>
      </v-col>

      <v-col
        v-if="selectedField.includes('is_locked')"
        class="d-flex px-1 pb-2"
        cols="12"
        :sm="!selectedField.includes('is_public') ? 12 : 6"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 4"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('is_locked'), 1)
              localValue.data.is_locked = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <v-chip
          class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.data.is_locked ? 'primary' : ''"
          @click="localValue.data.is_locked = !localValue.data.is_locked"
        >
          <v-icon
            v-if="localValue.data.is_locked"
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiLockOpenOutline }}
          </v-icon>
          <v-icon
            v-else
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiLockOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.data.is_locked ? 'Document dapat di-edit' : 'Document tidak dapat di-edit'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="selectedField.includes('is_public')"
        class="d-flex px-1 pb-2"
        cols="12"
        :sm="!selectedField.includes('is_locked') ? 12 : 6"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            v-if="actionType !== 4"
            text
            depressed
            class="mr-2 px-0"
            :color="hover ? 'error' : undefined"
            style="
              border-radius: 5px;
              min-width: 40px !important;
              height: 40px !important;
              border: solid 1px var(--color-foreground-high);
            "
            @click="
              selectedField.splice(selectedField.indexOf('is_public'), 1)
              localValue.data.name = undefined
            "
          >
            <v-icon size="22">
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </v-hover>

        <v-chip
          class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.data.is_public ? 'primary' : ''"
          @click="localValue.data.is_public = !localValue.data.is_public"
        >
          <v-icon
            v-if="localValue.data.is_public"
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiWeb }}
          </v-icon>

          <v-icon
            v-else
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiShieldOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{ localValue.data.is_public ? 'Visibilitas: Publik' : 'Visibilitas: Privat' }}
          </span>
        </v-chip>
      </v-col>

      <v-col
        v-if="selectedField.includes('document_tag')"
        class="d-flex pb-2 px-1"
        cols="12"
        md="6"
      >
        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.tag_ids"
          type="document-tag"
          @input="localValue.tag_ids = $event"
        />
      </v-col>

      <template v-if="selectedField.includes('user_permission_id')">
        <v-col
          class="px-1 mt-1 mb-3"
          cols="12"
        >
          <v-divider></v-divider>
        </v-col>

        <template v-if="localValue.data?.user_permissions">
          <template v-for="(user_permission, index) in localValue.data?.user_permissions">
            <v-col
              :key="`action-change-permission__action_${index}`"
              class="d-flex pb-2 px-1"
              cols="12"
              md="4"
              lg="3"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  text
                  depressed
                  class="mr-2 px-0"
                  :color="hover ? 'error' : undefined"
                  style="
                    border-radius: 5px;
                    min-width: 40px !important;
                    height: 40px !important;
                    border: solid 1px var(--color-foreground-high);
                  "
                  @click="localValue.data?.user_permissions.splice(index, 1)"
                >
                  <v-icon size="22">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </v-hover>

              <v-autocomplete
                v-model="user_permission.action"
                flat
                dense
                outlined
                auto-select-first
                hide-details="auto"
                placeholder="Pilih Aksi Assign"
                item-text="label"
                item-value="id"
                :rules="[required]"
                :items="actionUserPermissionList"
              >
                <template #selection="{ item }">
                  <v-icon
                    class="ml-1 mr-2"
                    size="20"
                    style="opacity: 0.8"
                  >
                    {{ item.icon }}
                  </v-icon>

                  {{ item.label }}
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center flex-grow-1 py-3">
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                      style="opacity: 0.8"
                    >
                      {{ item.icon }}
                    </v-icon>

                    <span class="subtitle-2"> {{ item.label }} </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              :key="`action-change-permission__user_${index}`"
              class="pb-2 px-1"
              cols="12"
              md="4"
              lg="6"
            >
              <AutomationCustomField
                :job-type-id="localJobType?.id"
                :value="user_permission.user_id"
                type="user-single-select"
                @input="user_permission.user_id = $event"
              />
            </v-col>

            <v-col
              :key="`action-change-permission__type_${index}`"
              class="pb-2 px-1"
              cols="12"
              md="4"
              lg="3"
            >
              <v-autocomplete
                v-model="user_permission.permission_type"
                flat
                dense
                outlined
                auto-select-first
                hide-details="auto"
                placeholder="Pilih Aksi Assign"
                item-text="label"
                item-value="id"
                :rules="[required]"
                :items="
                  localValue?.document_uuids
                    ? actionUserPermissionTypeListDocument
                    : actionUserPermissionTypeListJobType
                "
              >
                <template #selection="{ item }">
                  <v-icon
                    class="ml-1 mr-2"
                    size="20"
                    style="opacity: 0.8"
                  >
                    {{ item.icon }}
                  </v-icon>

                  {{ item.label }}
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center flex-grow-1 py-3">
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                      style="opacity: 0.8"
                    >
                      {{ item.icon }}
                    </v-icon>

                    <span class="subtitle-2"> {{ item.label }} </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </template>
        </template>
        <v-col
          class="d-flex px-1 pb-2"
          cols="12"
          md="3"
          lg="2"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              text
              depressed
              class="mr-2 px-0"
              :color="hover ? 'error' : undefined"
              style="
                border-radius: 5px;
                width: 100%;
                min-width: 40px !important;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              @click="
                selectedField.splice(selectedField.indexOf('user_permission_id'), 1)
                localValue.data.user_permissions = []
              "
            >
              <v-icon size="22">
                {{ icons.mdiDelete }}
              </v-icon>

              <h4 class="font-medium pl-2">Hapus Pilihan</h4>
            </v-btn>
          </v-hover>
        </v-col>

        <v-col
          class="d-flex px-1 pb-2"
          cols="12"
          md="9"
          lg="10"
        >
          <v-btn
            class="v-card v-sheet--outlined"
            depressed
            outlined
            style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
            @click="handleAddUserPermission()"
          >
            <h4 class="font-medium pl-2">Add User Permission</h4>
          </v-btn>
        </v-col>

        <v-col
          class="px-1 mt-1 mb-3"
          cols="12"
        >
          <v-divider></v-divider>
        </v-col>
      </template>

      <v-col
        class="px-1 pb-2"
        cols="12"
      >
        <v-menu
          bottom
          center
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="v-card v-sheet--outlined"
              depressed
              outlined
              style="
                border-radius: 5px;
                width: 100%;
                height: 40px !important;
                border: solid 1px var(--color-foreground-high);
              "
              v-bind="attrs"
              v-on="on"
            >
              <h4
                class="font-medium pl-2"
                style="color: inherit !important"
              >
                Choose Document Attributes
              </h4>
            </v-btn>
          </template>

          <v-card
            outlined
            class="d-flex flex-column pa-2"
          >
            <v-btn
              v-for="(option, index) in actionDocumentFieldList.filter(item =>
                actionType != 6 ? item.name != 'by_trigger' : item,
              )"
              :key="`automation_option_action_list${index}`"
              class="mb-2 px-0"
              depressed
              :disabled="
                (['name', 'description', 'is_public', 'is_locked'].includes(option.name) &&
                  actionType === 4) ||
                (option.name == 'document_tag' && actionType === 4)
              "
              :text="selectedField.includes(option.name) ? false : true"
              :color="selectedField.includes(option.name) ? 'primary' : ''"
              style="border-radius: 5px; min-width: 40px !important; height: 40px !important"
              @click="
                selectedField.includes(option.name)
                  ? (selectedField = selectedField.filter(item => item !== option.name))
                  : selectedField.push(option.name)
              "
            >
              <v-icon
                class="mx-2"
                size="20"
              >
                {{ option.icon }}
              </v-icon>

              <h4
                class="font-medium mr-auto"
                style="color: inherit !important"
              >
                {{ option.label }}
              </h4>
            </v-btn>
          </v-card>
        </v-menu>
      </v-col>

      <v-col
        v-if="actionType !== 6"
        class="px-1"
        cols="12"
      >
        <v-card
          class="px-9 py-2"
          min-height="500px"
          outlined
        >
          <TipTap
            ref="tiptap"
            :doc="localValue.yjs_doc"
            :editable="true"
            :isCollaborative="false"
            :document-content="localValue.content"
            style="max-width: 100%"
            class="mx-auto"
            mode="json"
            @update:yjs="localValue.yjs_doc = $event"
            @update:content="localValue.content = $event"
          />
        </v-card>
      </v-col>
    </template>

    <!-- Action Type – Send Notification -->
    <template v-else-if="actionType === 7">
      <v-col
        class="pb-2 px-1"
        cols="12"
      >
        <v-autocomplete
          v-model="localValue.object"
          flat
          dense
          outlined
          auto-select-first
          hide-details="auto"
          placeholder="Pilih Objek Notifikasi"
          item-text="label"
          item-value="id"
          :rules="[required]"
          :items="actionNotificationObject"
        >
          <template #selection="{ item }">
            <v-icon
              class="ml-1 mr-2"
              size="20"
              style="opacity: 0.8"
            >
              {{ item.icon }}
            </v-icon>

            {{ item.label }}
          </template>

          <template #item="{ item }">
            <div class="d-flex align-center flex-grow-1 py-3">
              <v-icon
                class="ml-1 mr-2"
                size="20"
                style="opacity: 0.8"
              >
                {{ item.icon }}
              </v-icon>

              <span class="subtitle-2"> {{ item.label }} </span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
        class="pb-2 px-1"
        cols="12"
      >
        <AutomationCustomField
          :job-type-id="localJobType?.id"
          :value="localValue.users_id"
          type="user"
          @input="localValue.users_id = $event"
        />
      </v-col>
    </template>

    <!-- Action Type – Change Permission Job -->
    <template v-else-if="[8].includes(actionType)">
      <!-- <template v-if="localValue?.document_uuids">
        <v-col
          v-if="localValue.documentByTrigger"
          class="pb-2 px-1"
          cols="12"
        >
          <span
            class="text-subtitle-2 d-flex"
            style="opacity: 0.75"
          >
            <v-icon
              class="mr-1"
              size="16"
              style="margin-top: 2.5px"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
            Permission Document yang akan diganti mengikuti Document yang ter-relasikan kepada Job
            yang memicu Trigger Automation ini.
          </span>
        </v-col>

        <v-col
          class="px-1 pb-2"
          cols="12"
          :md="localValue.documentByTrigger ? 12 : 4"
        >
          <v-chip
            class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
            style="border-radius: 6px; height: 40px"
            :color="localValue.documentByTrigger ? 'primary' : ''"
            @click="
              localValue.documentByTrigger = !localValue.documentByTrigger
              localValue.document_uuids = []
            "
          >
            <v-icon
              v-if="localValue.documentByTrigger"
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiPlayCircleOutline }}
            </v-icon>
            <v-icon
              v-else
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiLabelOutline }}
            </v-icon>

            <span class="ml-2 text-subtitle-2 text-truncate">
              {{
                localValue.documentByTrigger ? 'Document Trigger Bawaan' : 'Document yang Tersedia'
              }}
            </span>
          </v-chip>
        </v-col>

        <v-col
          v-show="!localValue.documentByTrigger"
          class="px-1 pb-2"
          cols="12"
          md="8"
        >
          <DocumentSelectInput
            :value="localValueSelectedDocument"
            @input="localValue.document_uuids = $event"
          >
            <template #activator="{ on, attrs, value }">
              <v-btn
                text
                v-on="on"
                v-bind="attrs"
                block
                outlined
                elevation="0"
                class="d-flex justify-start overflow-hidden w-full px-2"
                style="
                  border-radius: 5px;
                  min-width: 40px !important;
                  height: 40px !important;
                  border: solid 1px var(--color-foreground-high);
                "
              >
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ icons.mdiFileDocumentOutline }}
                </v-icon>

                <template v-if="value?.length > 0">
                  <v-chip
                    v-for="item in value.slice(0, 5)"
                    :key="item.uuid"
                    class="overflow-hidden"
                    small
                    label
                    style="cursor: pointer; max-width: 100%"
                    @click.prevent.stop
                  >
                    <span
                      class="text-truncate"
                      style="max-width: 100%"
                    >
                      {{ item.name.length >= 45 ? item.name.slice(0, 45) + '...' : item.name }}
                    </span>

                    <v-spacer />

                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon
                        v-show="hover"
                        class="ml-1"
                        size="14"
                      >
                        {{ mdiOpenInNew }}
                      </v-icon>
                    </v-slide-x-reverse-transition>
                  </v-chip>
                </template>

                <span
                  v-else
                  class="font-weight-regular"
                  style="opacity: 0.6"
                >
                  Tidak Ada Document yang Terpilih
                </span>
              </v-btn>
            </template>
          </DocumentSelectInput>
        </v-col>
      </template> -->

      <template v-if="localValue?.user_permissions.length > 0">
        <template v-for="(user_permission, index) in localValue?.user_permissions">
          <v-col
            :key="`action-change-permission__action_${index}`"
            class="d-flex pb-2 px-1"
            cols="12"
            md="4"
            lg="3"
          >
            <v-hover v-slot="{ hover }">
              <v-btn
                text
                depressed
                class="mr-2 px-0"
                :color="hover ? 'error' : undefined"
                style="
                  border-radius: 5px;
                  min-width: 40px !important;
                  height: 40px !important;
                  border: solid 1px var(--color-foreground-high);
                "
                @click="localValue?.user_permissions.splice(index, 1)"
              >
                <v-icon size="22">
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </v-hover>

            <v-autocomplete
              v-model="user_permission.action"
              flat
              dense
              outlined
              auto-select-first
              hide-details="auto"
              placeholder="Pilih Aksi Assign"
              item-text="label"
              item-value="id"
              :rules="[required]"
              :items="actionUserPermissionList"
            >
              <template #selection="{ item }">
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ item.icon }}
                </v-icon>

                {{ item.label }}
              </template>

              <template #item="{ item }">
                <div class="d-flex align-center flex-grow-1 py-3">
                  <v-icon
                    class="ml-1 mr-2"
                    size="20"
                    style="opacity: 0.8"
                  >
                    {{ item.icon }}
                  </v-icon>

                  <span class="subtitle-2"> {{ item.label }} </span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            :key="`action-change-permission__user_${index}`"
            class="pb-2 px-1"
            cols="12"
            md="4"
            lg="6"
          >
            <AutomationCustomField
              :job-type-id="localJobType?.id"
              :value="user_permission.user_id"
              type="user-single-select"
              @input="user_permission.user_id = $event"
            />
          </v-col>

          <v-col
            :key="`action-change-permission__type_${index}`"
            class="pb-2 px-1"
            cols="12"
            md="4"
            lg="3"
          >
            <v-autocomplete
              v-model="user_permission.permission_type"
              flat
              dense
              outlined
              auto-select-first
              hide-details="auto"
              placeholder="Pilih Aksi Assign"
              item-text="label"
              item-value="id"
              :rules="[required]"
              :items="
                localValue?.document_uuids
                  ? actionUserPermissionTypeListDocument
                  : actionUserPermissionTypeListJobType
              "
            >
              <template #selection="{ item }">
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                  style="opacity: 0.8"
                >
                  {{ item.icon }}
                </v-icon>

                {{ item.label }}
              </template>

              <template #item="{ item }">
                <div class="d-flex align-center flex-grow-1 py-3">
                  <v-icon
                    class="ml-1 mr-2"
                    size="20"
                    style="opacity: 0.8"
                  >
                    {{ item.icon }}
                  </v-icon>

                  <span class="subtitle-2"> {{ item.label }} </span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </template>
      </template>

      <v-col
        class="px-1"
        cols="12"
      >
        <v-btn
          class="v-card v-sheet--outlined"
          depressed
          outlined
          style="border-radius: 5px; width: 100%; border: solid 1px var(--color-foreground-high)"
          @click="handleAddUserPermission()"
        >
          <h4 class="font-medium pl-2">Add User Permission Change</h4>
        </v-btn>
      </v-col>
    </template>

    <!-- Action Type – Duplicate Job -->
    <template v-else-if="actionType == 9">
      <v-col
        class="pb-2 px-1"
        cols="12"
      >
        <v-card
          class="py-2 px-3"
          outlined
        >
          <span
            class="text-subtitle-2 font-weight-medium d-flex"
            style="opacity: 0.75"
          >
            <v-icon
              class="mr-2"
              size="18"
              style="margin-top: 2px"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
            Menduplikasikan Job memerlukan Status dan Priority secara bawaan, apabila data tersebut
            tidak ada, maka Job tidak akan berhasil di duplikasikan.
          </span>
        </v-card>
      </v-col>
      <v-col
        class="pb-2 px-1"
        cols="12"
        md="6"
      >
        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.job_priority_id"
          property="2"
          :type="attributePropertyMap[2].type"
          :icon="attributePropertyMap[2].icon"
          @input="localValue.job_priority_id = $event"
        />
      </v-col>
      <v-col
        class="pb-2 px-1"
        cols="12"
        md="6"
      >
        <AutomationCustomField
          :isEditing="isEditing"
          :value="localValue.job_status_id"
          property="3"
          :type="attributePropertyMap[3].type"
          :icon="attributePropertyMap[3].icon"
          @input="localValue.job_status_id = $event"
        />
      </v-col>

      <v-col
        class="px-1 pb-2"
        cols="12"
        md="6"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill px-3 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.description ? 'primary' : ''"
          @click="localValue.description = !localValue.description"
        >
          <v-icon
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiCalendarStartOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.description ? 'Deskripsi: Duplikasikan' : 'Deskripsi: Jangan Duplikasikan'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        class="px-1 pb-2"
        cols="12"
        md="6"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill px-3 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.document_linked ? 'primary' : ''"
          @click="localValue.document_linked = !localValue.document_linked"
        >
          <v-icon
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiCalendarCheckOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.document_linked
                ? 'Dokumen yang Ter-relasikan: Duplikasikan'
                : 'Dokumen yang Ter-relasikan: Jangan Duplikasikan'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="localValue.description || localValue.document_linked"
        class="pb-2 px-2"
        cols="12"
        md="6"
      >
        <span
          v-if="localValue.description"
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Deskripsi Job yang memicu Automation ini akan digunakan untuk Job Duplikat.
        </span>
      </v-col>
      <v-col
        v-if="localValue.document_linked"
        class="pb-2 px-2"
        cols="12"
        md="6"
      >
        <span
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Dokumen Ter-relasikan ke Job Akan Digunakan untuk Relasi Dokumen ke Job Duplikat.
        </span>
      </v-col>

      <v-col
        class="px-1 pb-2"
        cols="12"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill px-3 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.parent_id ? 'primary' : ''"
          @click="localValue.parent_id = !localValue.parent_id"
        >
          <v-icon
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiLinkVariant }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.parent_id
                ? 'Parent Job: Duplikasikan (Dengan Parent Job)'
                : 'Parent Job: Duplikasikan (Tanpa Parent Job)'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="localValue.parent_id"
        class="pb-2 px-2"
        cols="12"
      >
        <span
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Parent Job dari Job yang memicu Automation ini ini akan juga diduplikasikan ke destinasi
          yang diberikan.
        </span>
      </v-col>

      <v-col
        class="px-1 pb-2"
        cols="12"
        md="6"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill px-3 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.start_date ? 'primary' : ''"
          @click="localValue.start_date = !localValue.start_date"
        >
          <v-icon
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiCalendarStartOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.start_date
                ? 'Waktu Mulai: Duplikasikan'
                : 'Waktu Mulai: Jangan Duplikasikan'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        class="px-1 pb-2"
        cols="12"
        md="6"
      >
        <v-chip
          class="d-flex flex-column align-stretch flex-fill px-3 overflow-hidden"
          style="border-radius: 6px; height: 40px"
          :color="localValue.expected_close_date ? 'primary' : ''"
          @click="localValue.expected_close_date = !localValue.expected_close_date"
        >
          <v-icon
            size="20"
            style="color: inherit !important"
          >
            {{ icons.mdiCalendarCheckOutline }}
          </v-icon>

          <span class="ml-2 text-subtitle-2 text-truncate">
            {{
              localValue.expected_close_date
                ? 'Ekpektasi Waktu Tutup: Duplikasikan'
                : 'Ekpektasi Waktu Tutup: Jangan Duplikasikan'
            }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        v-if="localValue.start_date || localValue.expected_close_date"
        class="pb-2 px-2"
        cols="12"
        md="6"
      >
        <span
          v-if="localValue.start_date"
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Waktu Mulai Job yang memicu Automation ini akan digunakan untuk Job Duplikat.
        </span>
      </v-col>
      <v-col
        v-if="localValue.expected_close_date"
        class="pb-2 px-2"
        cols="12"
        md="6"
      >
        <span
          class="text-subtitle-2 d-flex"
          style="opacity: 0.75"
        >
          <v-icon
            class="mr-1"
            size="16"
            style="margin-top: 2.5px"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          Ekspektasi Waktu Tutup Job yang memicu Automation ini akan digunakan untuk Job Duplikat.
        </span>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { required } from '@/@core/utils/validation'
import TipTap from '@/components/editor/TipTap.vue'
import DocumentSelectInput from '@/components/inputs/editable/DocumentSelectInput.vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import UserLimitElements from '@/components/misc/UserLimitElements.vue'
import useDocument from '@/composables/useDocument'
import {
  actionAssignList,
  actionDateList,
  actionDocumentFieldList,
  actionJobFieldList,
  actionNotificationObject,
  actionUserPermissionList,
  actionUserPermissionTypeListDocument,
  actionUserPermissionTypeListJobType,
  attributePropertyMap,
  customAttributeTypeMap,
} from '@/constants/automation'
import { getJobTypeDetail, getJobTypes } from '@/services/jobTypeService'
import store from '@/store'
import dateFormat from '@/utils/dateFormat'
import {
  mdiBriefcaseOutline,
  mdiCalendarCheckOutline,
  mdiCalendarStartOutline,
  mdiClipboardAccountOutline,
  mdiDelete,
  mdiFileDocumentOutline,
  mdiFlagOutline,
  mdiFolder,
  mdiFolderLock,
  mdiInformationOutline,
  mdiLabelOutline,
  mdiLinkVariant,
  mdiLockOpenOutline,
  mdiLockOutline,
  mdiPlayCircleOutline,
  mdiShieldOutline,
  mdiTextLong,
  mdiWeb,
} from '@mdi/js'
import { merge } from 'lodash'
import { computed, inject, onBeforeMount, onMounted, provide, ref, watch } from 'vue'
import AutomationCustomField from './AutomationCustomField.vue'

export default {
  components: {
    AutomationCustomField,
    DocumentSelectInput,
    UserLimitElements,
    UserTeamSelector,
    TipTap,
  },

  props: {
    to_do: {
      required: true,
    },

    actionType: {
      require: true,
      type: Number,
    },
    isEditing: {
      require: true,
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const folderDetail = inject('folderDetail')
    const folderList = inject('folderList')
    const jobTypeDetail = inject('jobTypeDetail')
    const jobTypeList = inject('jobTypeList')
    const usersList = inject('usersList')
    const localValue = ref()
    const localFolder = ref({ ...folderDetail.value })
    const localJobType = ref({ ...jobTypeDetail.value })
    const localJobTypeList = ref([...jobTypeList.value])
    const isLoading = ref(true)

    provide('documentDetail', {})
    provide('folderDetail', localFolder)
    provide('jobTypeDetail', localJobType)

    /**
     * ? Data Type
     * name
     * priority
     * status
     * start_date
     * expected_end_date
     * assign_user_id
     */
    const selectedField = ref([])

    const openAssignee = ref(false)
    const localValueAssignee = computed({
      get() {
        return localValue.value?.assign?.assign_user_id
          ? localValue.value.assign.assign_user_id.slice(1)
          : []
      },
      set(value) {
        if (localValue.value?.assign) {
          localValue.value.assign.assign_user_id = [
            localValue.value.assign.assign_user_id[0],
            ...value,
          ]
        } else if (localValue.value) {
          localValue.value.assign = { assign_user_id: [...value] }
        }
      },
    })
    const localValueAssigneeAction_Type = computed({
      get() {
        return localValue.value?.assign?.assign_user_id[0] || 0
      },
      set(value) {
        if (localValue.value && localValue.value.assign) {
          localValue.value.assign.assign_user_id[0] = value
        }
      },
    })

    const { documentList, fetchDocumentParentHierarchy } = useDocument()
    const localValueSelectedDocument = computed(() =>
      localValue.value?.document_uuids?.map(document_uuid =>
        documentList.value.find(document => document.uuid == document_uuid),
      ),
    )

    const determineActionType = () => {
      if ([1, 2, 3].includes(props.actionType)) return 'job'
      if (props.actionType === 4) return 'document-add'
      if (props.actionType === 6) return 'document-update'
      if (props.actionType === 7) return 'send-notification'
      if (props.actionType === 8) return 'change-permission-job'
      if (props.actionType === 9) return 'duplicate'
      // if (props.actionType === 10) return 'change-permission-document'
    }

    const determineSelectedField = () =>
      new Promise(resolve => {
        if (localValue.value?.data?.name || localValue.value?.name) selectedField.value.push('name')

        if (localValue.value?.description) selectedField.value.push('description')
        if (localValue.value?.tag_ids) selectedField.value.push('document_tag')
        if (localValue.value?.data?.is_public) selectedField.value.push('is_public')
        if (localValue.value?.data?.is_locked) selectedField.value.push('is_locked')
        if (localValue.value?.data?.user_permission) selectedField.value.push('user_permission_id')

        if (localValue.value?.data?.job_priority_id) selectedField.value.push('priority')
        if (localValue.value?.data?.job_status_id) selectedField.value.push('status')
        if (localValue.value?.data?.start_date) selectedField.value.push('start_date')
        if (localValue.value?.data?.expected_close_date)
          selectedField.value.push('expected_close_date')
        if (localValue.value?.assign && localValue.value?.assign?.assign_user_id)
          selectedField.value.push('assign_user_id')

        resolve()
      })

    const determineFolderAndJobType = async () => {
      if (localFolder.value?.id === localValue.value?.folder_id) {
        localFolder.value = folderList.value.find(
          folder => folder?.id === localValue.value?.folder_id,
        )

        localJobTypeList.value = await getJobTypes(parseInt(localFolder.value?.id), {
          search: '',
          my_jobType: null,
          show_archived: 'IS NOT SET',
          sort: 'name',
          sortType: 'ASC',
        })
      }

      localJobType.value = await getJobTypeDetail({ jobTypeId: localValue.value?.job_type_id })
    }

    const updateDataByActionType = () => {
      if (determineActionType() === 'job') {
        if (props.actionType === 1) selectedField.value = ['name', 'priority', 'status']

        localValue.value = {
          nameByTrigger: undefined,

          data: {
            name: undefined,
            job_priority_id: undefined,
            job_status_id: undefined,
            folder_id: computed(() => localFolder.value?.id),
            job_type_id: computed(() => localJobType.value?.id),
            start_date: undefined,
            expected_close_date: undefined,
          },

          assign: undefined,
          // assign: {
          //   assign_user_id: [
          //     // ini merupakan action yang ingin dilakukan (Replace, Add, Remove, Toggle)
          //     // index "0" dari assign_user_id akan dianggap sebagai operator.
          //     0,
          //   ],
          // },

          custom_attribute: undefined,
        }
      }

      if (determineActionType() === 'send-notification') {
        localValue.value = {
          // Option : Job, Document, Expected Close Date
          object: 'job',
          users_id: [],
        }
      }

      if (determineActionType() === 'document-add') {
        selectedField.value = ['name', 'description', 'is_public', 'is_locked']

        localValue.value = {
          nameByTrigger: undefined,
          data: {
            parent_uuid: undefined,
            is_public: true,
            is_locked: false,
            folder_id: computed(() => localFolder.value?.id),
            workspace_id: computed(() => store.state.workspace.selectedWorkspace.workspace.id),
            user_permissions: [
              // {
              //   // 1 = Add, 2 = Updates
              //   action: 1,
              //   user_id: undefined,
              //   permission_type: 4,
              // },
            ],
          },

          name: '',
          yjs_doc: [0, 0],
          content: {
            type: 'doc',
            content: [],
          },
        }
      }

      if (determineActionType() === 'document-update') {
        localValue.value = {
          data: {
            parent_uuid: undefined,
            is_public: true,
            is_locked: false,
            folder_id: computed(() => localFolder.value?.id),
            workspace_id: computed(() => store.state.workspace.selectedWorkspace.workspace.id),
            user_permissions: [
              // {
              //   // 1 = Add, 2 = Updates
              //   action: 1,
              //   user_id: undefined,
              //   permission_type: 4,
              // },
            ],
          },

          name: '',
          document_uuids: undefined,
          documentByTrigger: false,
          tag_ids: undefined,
        }
      }

      if (determineActionType() === 'change-permission-job') {
        localValue.value = {
          folder_id: computed(() => localFolder.value?.id),
          job_type_id: computed(() => localJobType.value?.id),

          user_permissions: [
            {
              // 1 = Add, 2 = Updates
              action: 1,
              user_id: undefined,
              permission_type: 4,
            },
          ],
        }
      }

      if (determineActionType() === 'duplicate') {
        localValue.value = {
          folder_id: computed(() => localFolder.value?.id),
          job_type_id: computed(() => localJobType.value?.id),
          job_status_id: localJobType.value?.statuses[0]?.id,
          job_priority_id: localJobType.value?.priorities[0]?.id,

          parent_id: false,

          start_date: false,
          expected_close_date: false,
          description: true,
          document_linked: true,
        }
      }

      // if (determineActionType() === 'change-permission-document') {
      //   if (documentList.value.length == 0)
      //     fetchDocumentParentHierarchy({ rootOnly: false, filterSearchChildren: true })

      //   localValue.value = {
      //     folder_id: computed(() => localFolder.value?.id),
      //     job_type_id: computed(() => localJobType.value?.id),

      //     documentByTrigger: undefined,

      //     document_uuids: [
      //       // Array of strings
      //     ],
      //     user_permissions: [
      //       {
      //         // 1 = Add, 2 = Updates
      //         action: 1,
      //         user_id: undefined,
      //         permission_type: 4,
      //       },
      //     ],
      //   }
      // }
    }

    const handleUserAssigneeSelector = () => {
      openAssignee.value = !openAssignee.value

      if (!localValue.value.assign?.assign_user_id?.length > 0) return

      if (!localValue.value.assign?.assign_user_id)
        localValue.value.assign = {
          assign_user_id: [0],
        }

      const assignUserIds = localValue.value.assign.assign_user_id.slice(1)

      if (
        assignUserIds.length !== localValueAssignee.value.length ||
        assignUserIds.some((id, index) => id !== localValueAssignee.value[index])
      ) {
        localValue.value.assign.assign_user_id = [
          localValue.value.assign.assign_user_id[0],
          ...localValueAssignee.value,
        ]
      }
    }

    const handleAddCustomAttribute = () => {
      if (!Array.isArray(localValue.value.custom_attribute)) {
        localValue.value.custom_attribute = []
      }

      const selectedIds = localValue.value.custom_attribute.map(attr => attr.id)

      const availableAttributes = localJobType.value.custom_attributes.filter(item => {
        return !selectedIds.includes(item.id)
      })

      if (availableAttributes.length > 0) {
        const nextAttribute = availableAttributes[0]
        localValue.value.custom_attribute.push({
          id: nextAttribute.id,
          value: null,
        })
      }
    }

    const handleAddUserPermission = () => {
      if (localValue.value?.data?.user_permissions)
        return localValue.value.data.user_permissions.push({
          action: 1,
          user_id: undefined,
          permission_type: 4,
        })

      localValue.value.user_permissions.push({
        action: 1,
        user_id: undefined,
        permission_type: 4,
      })
    }

    const handleCustomAttributeSelector = attributeId =>
      customAttributeTypeMap[
        localJobType.value.custom_attributes.find(({ id }) => id === attributeId)?.data_type?.name
      ]

    onBeforeMount(updateDataByActionType)

    onMounted(async () => {
      console.log('data received, AFA => to_do', props.to_do)

      if (props.isEditing) {
        localValue.value = Array.isArray(props.to_do)
          ? merge({ ...localValue.value }, props.to_do[0])
          : merge({ ...localValue.value }, props.to_do)

        console.log('merged data', localValue.value)

        if (determineActionType() == 'job')
          await determineSelectedField().then(() => (isLoading.value = false))
        else if (determineActionType() == 'duplicate') {
          await determineFolderAndJobType()

          isLoading.value = false
        } else if (determineActionType() == 'change-permission-document') {
          await fetchDocumentParentHierarchy({ rootOnly: false, filterSearchChildren: true })

          isLoading.value = false
        } else isLoading.value = false
      } else isLoading.value = false
    })

    watch(localFolder, async (newFolder, oldFolder) => {
      if (isLoading.value) return

      localJobTypeList.value = await getJobTypes(parseInt(localFolder.value.id), {
        search: '',
        my_jobType: null,
        show_archived: 'IS NOT SET',
        sort: 'name',
        sortType: 'ASC',
      })

      if (localJobType.value === undefined || newFolder.id !== oldFolder.id)
        localJobType.value = localJobTypeList.value[0]
    })

    watch(localJobType, async (newDetail, oldDetail) => {
      if (isLoading.value) return

      if (newDetail?.id === oldDetail?.id) return
      else
        await getJobTypeDetail({ jobTypeId: newDetail.id }).then(data => {
          localJobType.value = data

          if (props.actionType == 9) {
            localValue.value.job_status_id = data?.statuses[0]?.id
            localValue.value.job_priority_id = data?.priorities[0]?.id
          }
        })
    })

    watch(
      () => props.actionType,
      () => {
        localValue.value = undefined
        localValueAssignee.value = undefined
        selectedField.value = []
        localFolder.value = { ...folderDetail.value }
        localJobType.value = { ...jobTypeDetail.value }
        emit('input', undefined)

        updateDataByActionType()
      },
    )

    watch(
      localValue,
      () => {
        emit('input', localValue.value)
      },
      {
        deep: true,
      },
    )

    watch(
      selectedField,
      () => {
        if (isLoading.value && props.isEditing) return

        if (selectedField.value.includes('assign_user_id'))
          localValue.value.assign = {
            assign_user_id: [0],
          }

        actionJobFieldList.forEach(field => {
          if (!selectedField.value.includes(field.name)) {
            if (field.name in localValue.value.data) localValue.value.data[field.name] = undefined
            else if (field.name === 'assign_user_id') {
              localValueAssignee.value = []
              localValue.value.assign = {}
            }
          }
        })
      },
      { deep: true },
    )

    return {
      folderDetail,
      folderList,
      jobTypeDetail,
      jobTypeList,
      usersList,
      localValue,
      localFolder,
      localJobType,
      localJobTypeList,
      isLoading,
      selectedField,
      openAssignee,
      localValueAssignee,
      localValueAssigneeAction_Type,
      localValueSelectedDocument,

      attributePropertyMap,
      customAttributeTypeMap,
      actionJobFieldList,
      actionDocumentFieldList,
      actionDateList,
      actionAssignList,
      actionUserPermissionList,
      actionUserPermissionTypeListJobType,
      actionUserPermissionTypeListDocument,
      actionNotificationObject,

      handleAddCustomAttribute,
      handleCustomAttributeSelector,
      handleUserAssigneeSelector,
      handleAddUserPermission,

      dateFormat,
      required,

      icons: {
        mdiBriefcaseOutline,
        mdiFlagOutline,
        mdiInformationOutline,
        mdiPlayCircleOutline,
        mdiLabelOutline,
        mdiCalendarStartOutline,
        mdiCalendarCheckOutline,
        mdiDelete,
        mdiClipboardAccountOutline,
        mdiFolder,
        mdiFolderLock,
        mdiFileDocumentOutline,
        mdiWeb,
        mdiShieldOutline,
        mdiLockOutline,
        mdiLockOpenOutline,
        mdiTextLong,
        mdiLinkVariant,
      },
    }
  },
}
</script>
