var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-regular",staticStyle:{"padding-left":"5px","padding-right":"5px"},attrs:{"text":"","x-small":"","elevation":"0"}},'v-btn',attrs,false),{
          ...on,
          click: e => {
            e.preventDefault()
            e.stopPropagation()
            _setup.handleOpen()
          },
        }),[_vm._t("default",function(){return [(_vm.value.length)?_c('div',{staticClass:"multi-select-input"},_vm._l((_vm.value),function(item){return _c('v-chip',{key:item.id,staticStyle:{"cursor":"pointer"},attrs:{"color":item.color,"x-small":"","label":""}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('span',[_vm._v("-")])]})],2)]},{"on":{
        ...on,
        click: e => {
          e.preventDefault()
          e.stopPropagation()
          _setup.handleOpen()
        },
      },"attrs":attrs,"editMode":_setup.editMode})]}}],null,true),model:{value:(_setup.editMode),callback:function ($$v) {_setup.editMode=$$v},expression:"editMode"}},[_c(_setup.ItemSelect,{attrs:{"items":_vm.items,"multiple":true},model:{value:(_setup.localValue),callback:function ($$v) {_setup.localValue=$$v},expression:"localValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }