<template>
  <div
    class="app-content-container mx-auto h-full"
    :class="{ 'app-content-container-boxed': appContentWidth === 'boxed' }"
  >
    <slot />
  </div>
</template>

<script>
import useSubscriptionV1 from '@/composables/useSubscriontion'
import { notificationSubscription } from '@/graphql/queries'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { watch } from 'vue'

export default {
  setup() {
    const { appContentWidth } = useAppConfig()

    const { result: notificationUpdate } = useSubscriptionV1(notificationSubscription, {
      user_id: store.getters.getUserData.id,
    })
    watch(notificationUpdate, ({ notificationSubscription }) => {
      if (notificationSubscription.recipient_user.id !== store.getters.getUserData.id) return

      store.dispatch('updateSubscriptionNotification', notificationSubscription)
    })

    return {
      appContentWidth,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';

.app-content-container {
  &.app-content-container-boxed {
    max-width: $content-max-width;
  }
}
</style>
